<!-- 三级类目管理 -->
<template>
  <a-button style="margin-bottom: 10px" type="primary" @click="newCreate()">新建三级类目</a-button>
  <a-table bordered rowKey="logicId" size="middle" :columns="columns" :data-source="source" :pagination="pagination"
    :loading="pagination.loading" @change="handleTableChange">
    <template #operation="{ record }">
      <a-button type="primary" size="small" @click="editCates(record)">修改</a-button>
    </template>
  </a-table>
  <a-modal :title="modifyModal.title" v-model:visible="modifyModal.visible" :confirm-loading="modifyModal.loading"
    @ok="handleOk" @cancel="handlerCancel">
    <a-form ref="formRef" :model="modifyModal.data" :rules="rulesMod" :label-col="{ span: 5 }"
      :wrapper-col="{ span: 19 }">
      <a-form-item label="类目名称" name="name">
        <a-input v-model:value="modifyModal.data.name" />
      </a-form-item>
      <a-form-item label="上级类目" name="parentId">
        <a-select v-model:value="modifyModal.levelVal1" placeholder="请选择一级类目" style="width: 50%"
          @change="handleChange('parentId')">
          <a-select-option v-for="(item, i) in modifyModal.levelArr1" :key="i" :value="item.logicId">{{ item.name }}
          </a-select-option>
        </a-select>
        <a-select v-model:value="modifyModal.data.parentId" placeholder="请选择二级类目" style="width: 50%"
          @change="handleChange('parentId')">
          <a-select-option v-for="(item, i) in level2ArrByType" :key="i" :value="item.logicId">{{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="规格类型" name="unitType">
        <a-select v-model:value="modifyModal.data.unitType" placeholder="请选择" style="width: 50%">
          <a-select-option v-for="item in unitTypeArr" :key="item.value" :value="item.value">{{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="规格单位" name="unit">
        <a-input v-model:value="modifyModal.data.unit" @change="handleChange('unit')" />
      </a-form-item>
      <a-form-item label="规格系数" name="unitRatio">
        <a-input v-model:value.number="modifyModal.data.unitRatio" type="number" @change="handleChange('unitRatio')" />
      </a-form-item>
      <a-form-item label="规格选项" name="unitOptionList">
        <a-textarea v-model:value="modifyModal.data.unitOptionList" :rows="4" @change="handleChange('unitOptionList')">
        </a-textarea>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
import { ref, onMounted, computed } from "vue";
import { page, saveCategoryNew, getAllCategoryTwo } from "@/api/category";
import { query as querys } from "@/api/public";
import { message } from "ant-design-vue";
export default {
  setup() {
    const {
      formRef,
      columns,
      pagination,
      source,
      modifyModal,
      editCates,
      handlerCancel,
      handleOk,
      newCreate,
      handleChange,
      level2ArrByType,
      handleTableChange,
      rulesMod,
      unitTypeArr
    } = useCategory2Manage();
    return {
      formRef,
      columns,
      pagination,
      source,
      modifyModal,
      editCates,
      handlerCancel,
      handleOk,
      newCreate,
      handleChange,
      level2ArrByType,
      handleTableChange,
      rulesMod,
      unitTypeArr
    };
  }
};
function useCategory2Manage() {
  const columns = [
    { title: "ID", dataIndex: "logicId" },
    { title: "标题", dataIndex: "name" },
    {
      title: "操作",
      dataIndex: "operation",
      slots: { customRender: "operation" }
    }
  ];
  const source = ref([]); // 显示的表格数据
  const formRef = ref();
  const modifyModal = ref({
    title: "",
    visible: false,
    loading: false,
    form: {},
    data: {
      unitType: "1"
    },
    levelVal1: null,
    levelArr1: [],
    levelArr2: [],
    levelArr3: []
  });

  const pagination = ref({
    loading: false,
    position: "bottom",
    total: 0, // 条数
    defaultPageSize: 20, // 默认分页条数
    pageSizeOptions: ["5", "10", "15", "20", "30"],
    showSizeChanger: true, // 是否可以改变 pageSize
    showQuickJumper: true, // 是否可以快速跳转至某页
    hideOnSinglePage: false, // 只有一页时是否隐藏分页器
    showTotal: total => `共 ${total} 条`
  });
  const paginationOption = ref({
    pageNo: 1,
    pageSize: pagination.value.defaultPageSize
  });

  const unitTypeArr = [
    { label: "无", value: "0" },
    { label: "数字类型", value: "1" },
    { label: "选项类型", value: "2" }
  ];

  const handleTableChange = pagination => {
    const { current, pageSize } = pagination;
    paginationOption.value = {
      pageNo: current,
      pageSize
    };
    getCategoryByLevel({ pageNo: current, pageSize, level: 3 });
  };

  // 触发修改页面
  const editCates = row => {
    const {
      logicId,
      name,
      unit,
      unitRatio,
      parentId,
      cat1stId,
      unitType,
      unitOptionList
    } = row;
    modifyModal.value.data = {
      logicId,
      name,
      unit: unit,
      unitRatio: unitRatio,
      parentId: parentId,
      unitType: unitType.toString(),
      unitOptionList: unitOptionList ? unitOptionList.join("\n") : null
    };
    modifyModal.value.levelVal1 = cat1stId;
    modifyModal.value.title = "修改三级类目";
    modifyModal.value.visible = true;
  };
  // 触发新建页面
  const newCreate = () => {
    modifyModal.value.title = "新建三级类目";
    modifyModal.value.visible = true;
    modifyModal.value.data = {
      logicId: null,
      unitType: "1"
    };
  };
  const handlerCancel = async () => {
    await formRef.value.resetFields();
    modifyModal.value.loading = false;
    modifyModal.value.levelVal1 = null;
    modifyModal.value.visible = false;
  };
  const handleOk = () => {
    formRef.value
      .validate()
      .then(async () => {
        modifyModal.value.loading = true;
        const params = {
          level: 3,
          ...modifyModal.value.data,
          unitType: modifyModal.value.data.unitType === '0' ? '' : modifyModal.value.data.unitType
        };
        if (modifyModal.value.data.unitOptionList) {
          params.unitOptionList = modifyModal.value.data.unitOptionList.split(
            "\n"
          );
        }
        const { status, msg } = await saveCategoryNew(params);
        if (status === "200") {
          message.success(msg);
          handlerCancel();
          getCategoryByLevel({ ...paginationOption.value, level: 3 });
          getAllCategoryReq();
        } else {
          modifyModal.value.loading = false;
          message.error(msg);
        }
      })
      .catch(error => {
        console.log("error", error);
      });
  };

  // 类目选择
  const handleChange = type => {
    formRef.value.validateFields([type], { force: true });
  };

  // 根据类目等级获取类目列表
  const getCategoryByLevel = async params => {
    const { level } = params;
    if (level === 3) {
      pagination.value.loading = true;
    }
    const { data } = await page(params);
    switch (level) {
      case 1:
        modifyModal.value.levelArr1 = data.dataOnThisPage || [];
        break;
      case 2:
        modifyModal.value.levelArr2 = data.dataOnThisPage || [];
        break;
      case 3:
        pagination.value.loading = false;
        pagination.value.total = data.total || 0;
        source.value = data.dataOnThisPage || [];
        break;
    }
  };

  // 所有类目
  const getAllCategoryReq = async () => {
    const { data } = await getAllCategoryTwo();
    modifyModal.value.levelArr1 = data || [];
  };

  const level2ArrByType = computed(() => {
    const result = modifyModal.value.levelArr1.filter(
      el => el.logicId === modifyModal.value.levelVal1
    );
    return result.length > 0 ? result[0].childList : [];
  });

  const rulesMod = computed(() => {
    const rules = {
      name: { required: true, message: "请输入类目名称", trigger: "blur" },
      parentId: {
        required: true,
        message: "请选择二级类目",
        trigger: "change"
      },
      // unitType: {
      //   required: true,
      //   message: "请选择规则类型",
      //   trigger: "change"
      // }
    };
    if (modifyModal.value.data.unitType === "1") {
      rules.unitRatio = {
        required: true,
        message: "请输入规格系数",
        trigger: "change",
        type: "number"
      };
      rules.unit = {
        required: true,
        message: "请输入规格单位",
        trigger: "change"
      };
    } else if (modifyModal.value.data.unitType === "2") {
      rules.unitOptionList = {
        required: true,
        message: "请输入规格选项",
        trigger: "change"
      };
    }
    return rules;
  });

  onMounted(() => {
    getCategoryByLevel({
      pageNo: 1,
      pageSize: pagination.value.defaultPageSize,
      level: 3
    });
    getAllCategoryReq();
  });
  return {
    formRef,
    columns,
    pagination,
    source,
    modifyModal,
    editCates,
    handlerCancel,
    handleOk,
    newCreate,
    handleChange,
    level2ArrByType,
    handleTableChange,
    rulesMod,
    unitTypeArr
  };
}
</script>

<style>
.star {
  display: flex;
}
</style>
