import { request, requestJSON } from "../utils/axios";
import { stringify } from "qs";

// 新增或修改二、三级分类
export function saveOrUpdate(params) {
  return request({
    url: `/wuan/admin/star/type/saveOrUpdate?${stringify(params)}`,
    method: "post",
  });
}
export function page(params) {
  return request({
    url: `/wuan/admin/star/type/page?${stringify(params)}`,
    method: "get",
  });
}
export function detail(params) {
  return request({
    url: `/wuan/admin/star/type/detail?${stringify(params)}`,
    method: "get",
  });
}

export function saveCategory(params) {
  return request({
    url: `/wuan/admin/goods/type/saveOrUpdate?${stringify(params)}`,
    method: "post",
  });
}

// 查询所有类目（树形）
export function getAllCategory() {
  return request({
    url: `/star/type/typeQuery`,
    method: "get",
  });
}

// 查询所有类目
export function getAllCategoryTwo() {
  return request({
    url: "/post/type/tree",
    method: "get",
  });
}

// 分页查询类目
export function getCategoryPage(params) {
  return request({
    url: `/wuan/admin/post/page?${stringify(params)}`,
    method: "get",
  });
}

// 保存类目
export function saveCategoryNew(params) {
  return requestJSON({
    // url: `/wuan/admin/post/saveOrUpdate?${stringify(params)}`,
    url: "/wuan/admin/post/saveOrUpdate",
    method: "post",
    data: params,
  });
}
